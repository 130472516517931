import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private loaderSubject = new Subject<SpinnerState>();

  spinnerState = this.loaderSubject.asObservable();

  constructor() { }

  show() {
    this.loaderSubject.next(<SpinnerState>{ show: true });
  }

  hide() {
    this.loaderSubject.next(<SpinnerState>{ show: false });
  }
}

export interface SpinnerState {
  show: boolean;
}