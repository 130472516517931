import { Injectable, Injector } from '@angular/core';

import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Observable, of, throwError, Subscription, Subject } from 'rxjs';
import { tap, catchError, map, finalize, takeUntil } from 'rxjs/operators';

import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from './Loader.Service';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'text/plain'
  })
};
const headers = new HttpHeaders().set('content-type', 'application/json');
export class GenricService {
  private _subscription: Subscription;

  private BaseUrl: string = environment.API_URL; // `${this.url}api/`;

  protected http: HttpClient;

  protected router: Router;

  protected toastr: ToastrService;

  constructor(protected injector: Injector) {
    this.http = injector.get(HttpClient);
    this.router = injector.get(Router);
    this.toastr = injector.get(ToastrService);
  }

  private Unsubscribe() {
    this._subscription.unsubscribe();
  }

  private unsub: Subject<any> = new Subject();

  /** Get */
  Get<T>(T, endpoint: string, filters): Observable<any> {

    let params = new HttpParams();
    if (filters) {

      const keys = Object.keys(filters);
      keys.map(key => {
        const value = filters[key];
        params = params.append(key, value);
        return true;
      });
    }

    return this.http
      .get<T>(`${this.BaseUrl}${endpoint}`, {
        params,
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        tap(res => {}),
        takeUntil(this.unsub)
      );
  }

  /**  Add  */
  Post<T>(T, endpoint: string, Data: FormData): Observable<any> {

    return this.http
      .post<T>(`${this.BaseUrl}${endpoint}`, Data, { withCredentials: true, observe: 'response' })
      .pipe(
        tap(res => {}),
        takeUntil(this.unsub)
      );
  }

  /**  Update  */
  Put<T>(T, endpoint: string, Data: FormData | any): Observable<any> {
    return this.http
      .put<T>(`${this.BaseUrl}${endpoint}`, Data, { withCredentials: true, observe: 'response' })
      .pipe(
        tap(res => {}),
        takeUntil(this.unsub),
        finalize(() => {})
      );
  }

  /**  Delete  */
  Delete<T>(T, endpoint: string): Observable<any> {
    return this.http
      .delete<T>(`${this.BaseUrl}${endpoint}`, { withCredentials: true, observe: 'response' })
      .pipe(
        tap(res => {}),
        takeUntil(this.unsub)
      );
  }

  Destroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  toastrSuccess(msg) {
    this.toastr.success(msg, 'Success');
  }

  toastrError(msg) {
    this.toastr.error(msg, 'Error');
  }

  toastrInfo(msg) {
    this.toastr.info(msg, 'Info');
  }

  toastrWarn(msg) {
    this.toastr.warning(msg, 'Warn');
  }
}