import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NpsComponent } from './nps.component';
import { SharedModule } from 'src/app/shared-module/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [NpsComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    MatTooltipModule
  ],
  exports: [NpsComponent]
})
export class NpsModule { }