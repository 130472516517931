import { Injectable, Injector } from '@angular/core';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { BasicService } from './lib/Basic.Service';
import { config, cashier } from '../models';
import {
  PHONE_PATTERN,
  EMAIL_PATTERN,
  instagramPattern,
  twitterPattern
} from '../reusable-components/form-fields/validation-errors/validation-patterns-list';
import { Helper } from './lib/Helper.service';
import { PaginationDetails, ResponseData, ServerPaginationResponse } from '../models/pagination.models';
import { Observable, throwError } from 'rxjs';
import { mapPaginationParams, mapPaginationResponse } from '../utils/pagination.utils';
import { catchError, map } from 'rxjs/operators';
import { Branch } from '../models/branch.models';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BranchesService extends BasicService<config<any>> {
  baseUrl = `${environment.API_URL}branches`;
  constructor(protected injector: Injector) {
    super('branches/', injector);
  }

  getAllBranchesData(filter) {
    const endpoint = `${this.BaseEndPoint}`;
    return this.Get(this.ReturnType, endpoint, filter);
  }

  // This should replace getAllBranchesData gradually
  branchesList(params: { [key: string]: any }, paginationParams: PaginationDetails): Observable<ResponseData<Branch>> {
    return this.http.get<ServerPaginationResponse<any>>(`${this.baseUrl}/`, {
      params: {
        ...params,
        ...mapPaginationParams(paginationParams)
      }
    }).pipe(
      map(res => mapPaginationResponse(res).data as ResponseData<Branch>)
    );
  }

  getBusinessData(id: string) {
    const endpoint = `businesses/${id}`;
    return this.Get(this.ReturnType, endpoint, '');
  }

  updateLocation(id, form) {
    const endpoint = `${this.BaseEndPoint}${id}/position`;
    return this.Put(this.ReturnType, endpoint, form);
  }

  upateWorkTime(id, data) {
    const endpoint = `${this.BaseEndPoint}${id}/opening-periods`;
    return this.Put(this.ReturnType, endpoint, data);
  }

  updateSliderPhotos(id, form) {
    const Data = Helper.GetFormDataFromFormGroup(form);
    const endpoint = `${this.BaseEndPoint}${id}/slider-photos`;
    return this.Put(this.ReturnType, endpoint, Data);
  }


  getBasicInfo(id, filter) {
    const endpoint = `${this.BaseEndPoint}${id}`;
    return this.Get(this.ReturnType, endpoint, filter);
  }

  updateBasicIfno(id, form) {
    const Data = Helper.GetFormDataFromFormGroup(form);
    const endpoint = `${this.BaseEndPoint}${id}`;
    return this.Put(this.ReturnType, endpoint, Data);
  }

  getWebpickupSetting(id, filter) {
    const endpoint = `${this.BaseEndPoint}${id}/branch_web_details`;
    return this.Get(this.ReturnType, endpoint, filter);
  }

  updateWebpickupSetting(id, form) {
    const Data = Helper.GetFormDataFromFormGroup(form);
    const endpoint = `${this.BaseEndPoint}${id}/branch_web_details`;
    return this.Put(this.ReturnType, endpoint, Data);
  }

  getBrancheState(filter) {
    const endpoint = `${this.BaseEndPoint}` + 'stats';
    return this.Get(this.ReturnType, endpoint, filter);
  }

  addMarnBranch(locationKey): Observable<any> {
    const data = {
      marnLocationKey: locationKey
    };
    return this.http.post(`${this.baseUrl}/add-from-marn`, data);
  }
  updateBranchStatus(branchId: string, body: object): Observable<any> {
    return this.http.put(`${this.baseUrl}/partner/${branchId}/update-status`, body);
  }
  checkFoodicsHealth(param): Observable<any> {
    return this.http.get(`${this.baseUrl}/check-foodics-health`, { params: param })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  bulkUpdateOpeningPeriods(body: object): Observable<any> {
    return this.http.patch(`${this.baseUrl}/partner/bulk-update-opening-periods`, body);
  }

  branchSubscriptionView() {
    return this.http.get(`${this.baseUrl}/partner/subscription-ending-soon-or-ended`);
  }
}