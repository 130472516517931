import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BranchService } from '../Branch.Service';

@Injectable({
  providedIn: 'root'
})

export class ArchiAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private branchService: BranchService
  ) {
  }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = JSON.parse(localStorage.getItem('User'));
    const result: any = await this.branchService.getBusinessData(user.id).toPromise();
    if (result?.body?.data?.brand?._id === '63af0d2edb097543faec3964') {
      return true;
    }
    this.router.navigate(['brands']);
    return false;
  }

}