import { Injectable, Injector } from '@angular/core';
import { BasicService } from './lib/Basic.Service';
import { environment } from './../../environments/environment';
import { config } from '../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BrandService extends BasicService<config<any>> {

  brandsBaseApiUrl = `${environment.API_URL}brands`;

  constructor(protected injector: Injector) {
    super('brands/', injector);
  }

  getBrandProductsWithModifires() {
    const endpoint = 'brands/menu';
    return this.Get(this.ReturnType, endpoint, '');
  }

  brandDetails(id: string): Observable<any> {
    return this.http.get(`${this.brandsBaseApiUrl}/${id}`).pipe(
      map((resp: any) => resp.data.brands)
    );
  }
  syncall(data): Observable<any> {
    return this.http.post(`${this.brandsBaseApiUrl}/sync-tables`, data);
  }

  updateBrand(data: object) {
    return this.http.put(`${this.brandsBaseApiUrl}/partner/update`, data);
  }

  getMarnConfigurations(id) {
    return this.http.get(`${this.brandsBaseApiUrl}/${id}/marn/config`);
  }

  configureMarn(id, formData) {
    const data = {
      hid: formData.get('marnBrandKey')
    };
    return this.http.put(`${this.brandsBaseApiUrl}/${id}/marn/config`, data);
  }

  getMarnLocations(id) {
    return this.http.get(`${this.brandsBaseApiUrl}/${id}/marn/locations`);
  }

  // get brand tag
  getBrandTag() {
    return this.http.get(`${this.brandsBaseApiUrl}/partner/view-brand-tag`);
  }

  editBrandTag(payload: any) {
    return this.http.put(`${this.brandsBaseApiUrl}/partner/update-brand-tag`, payload);
  }
  viewSubscriptionPackages() {
    return this.http.get(`${this.brandsBaseApiUrl}/partner/view-subscription-packages`);

  }
}