import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';



@Injectable({
  providedIn: 'root'
})
export class CookieLangService {

lang: any;
constructor(public translate: TranslateService, private cookie: CookieService) {}

  getLangFromCookie = () => {
    this.lang = this.cookie.get('lang');
    this.translate.onLangChange.subscribe((event: any) => { this.lang = event.lang; });
  };

}