import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { LoginModule } from './pages/login/login.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { UntypedFormBuilder } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CookieModule } from 'ngx-cookie';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './layout/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';

import { FooterComponent } from './layout/footer/footer.component';
import { Error404Component } from './pages/error404/error404.component';
import { AdminAuthService } from './services/AdminAuth.Service';
import { InterceptorService } from './services/lib/HttpRequest.interceptor';
import { SpinnerService } from './services/lib/Loader.Service';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { BusinessService } from 'src/app/services/business.Service';
import { constants, CONSTANTS } from './constants/constants';
import { SentryErrorHandlerService } from './services/sentry-error-handler.service';
import { GestureConfig } from '../gesture-config';
import { BrandService } from 'src/app/services/Brand.Service';
import { NpsModule } from './reusable-components/nps/nps.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LayoutComponent,
    SideMenuComponent,
    BreadcrumbComponent,
    Error404Component,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    DashboardModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    CookieModule.forRoot(),
    MatExpansionModule,
    NgxSpinnerModule,
    LoginModule,
    // public
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HammerModule,
    NpsModule
  ],
  providers: [
    AdminAuthService,
    SpinnerService,
    BusinessService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    BsModalService,
    UntypedFormBuilder,
    ToastrService,
    { provide: CONSTANTS, useValue: constants },
    { provide: ErrorHandler, useClass: SentryErrorHandlerService },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    BrandService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }