import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { NpsService } from '../services/nps.service';
import { AdminAuthService } from '../services/AdminAuth.Service';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  view_header_footer: boolean = false;
  showFiller: boolean = true;
  lang: any;
  showNps: boolean;
  constructor(
    public translate: TranslateService,
    private cookie: CookieService,
    private npsService: NpsService,
    private adminService: AdminAuthService
  ) { }

  ngOnInit() {
    this.lang = this.cookie.get('lang');
    this.translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
    });
    const user = JSON.parse(localStorage.getItem('User'));
    if (user != null) {
      this.view_header_footer = true;
    }

    const isAgent = localStorage.getItem('isAgent');
    this.adminService.isAuthenticated().then((res: any) => {
      if (res.IsLoggedIn && isAgent !== 'true') {
        this.checkShowNps();
      }
    });

  }

  checkShowNps() {
    this.npsService.checkIfPartnerShouldRate().subscribe(
      (response: any) => {
        if (response.data.businessShouldRate) {
          this.showNps = true;
        }
      }
    );
  }

  openSide() {
    this.showFiller = true;

  }
}