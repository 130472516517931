import { Component } from '@angular/core';
import { single } from './data';

@Component({
  selector: 'app-bar-chart-single',
  templateUrl: './bar-chart-single.component.html',
  styleUrls: ['./bar-chart-single.component.scss']
})
export class BarChartSingleComponent {
  single: any[];

  multi: any[];

  view: any[] = [230, 180];

  // options
  showXAxis = true;

  showYAxis = true;

  gradient = false;

  showLegend = false;

  showXAxisLabel = false;

  xAxisLabel = '';

  showYAxisLabel = false;

  yAxisLabel = '';

  barPadding = 22;

  colorScheme = {
    domain: ['#04043d', ' #f2bc3d', '#a1d4bb', '#cee9eb', '#9ba3a3']
  };

  constructor() {
    Object.assign(this, { single });
  }

  onSelect(event) {}
}