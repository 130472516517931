import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BrandService } from '../Brand.Service';

@Injectable({
  providedIn: 'root'
})

export class LoyaltyOrFreemiumAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private brandService: BrandService
  ) {
  }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const result: any = await this.brandService.viewSubscriptionPackages().toPromise();
    if (result.data.subscriptionPackages.includes('loyalty')) {
      return true;
    }
    if (result.data.subscriptionPackages.includes('freemium')) {
      return true;
    }
    this.router.navigate(['brands']);
    return false;
  }

}