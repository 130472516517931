import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MaterialImportsModule } from './material-imports/material-imports.module';
import { NgxBootstrapImportsModule } from './ngx-bootstrap-imports/ngx-bootstrap-imports.module';
import { TranslateModule } from '@ngx-translate/core';

const exportedModules = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  MaterialImportsModule,
  NgxBootstrapImportsModule,
  MatIconModule,
  TranslateModule
];

@NgModule({
  declarations: [],
  imports:
    [
      CommonModule,
      RouterModule,
      ReactiveFormsModule,
      FormsModule,
      MaterialImportsModule,
      NgxBootstrapImportsModule,
      MatIconModule,
      TranslateModule,
      PaginationModule.forRoot()
    ],
  exports:
    [
      CommonModule,
      RouterModule,
      ReactiveFormsModule,
      FormsModule,
      MaterialImportsModule,
      NgxBootstrapImportsModule,
      MatIconModule,
      TranslateModule
    ]
})
export class SharedModule { }