import { Component } from '@angular/core';

@Component({
  template: `

  `,
  styles: [
    `
           main {
             height: 100%;
             background-color: white;
             position: relative;
           }
         `
  ]
})
export class LoginRouteComponent {}