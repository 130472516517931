import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RatingModule } from 'ngx-bootstrap/rating';
import { BranchService } from 'src/app/services/Branch.Service';
import { DashboardService } from 'src/app/services/dashboard.Service';
import { BarChartSingleComponent } from './bar-chart-single/bar-chart-single.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { CardNumberComponent } from './card-number/card-number.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { LineChartComponent } from './line-chart/line-chart.component';

import { MultipieComponent } from './multipie/multipie.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxDonutChartModule } from 'ngx-doughnut-chart';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from 'src/app/reusable-components/spinner/spinner.module';
import { SharedModule } from 'src/app/shared-module/shared.module';

@NgModule({
  declarations: [
    DashboardComponent,
    CardNumberComponent,
    LineChartComponent,
    BarChartComponent,
    BarChartSingleComponent,

    MultipieComponent,

    PieChartComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    NgxChartsModule,
    NgxDonutChartModule,
    ModalModule,
    RatingModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    SpinnerModule,
    TranslateModule,
    SharedModule
  ],
  providers: [DashboardService, BranchService]
})
export class DashboardModule { }