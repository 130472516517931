import { Injectable, Injector } from '@angular/core';
import { Validators, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BasicService } from './lib/Basic.Service';
import { config, business } from '../models';
import { Helper } from './lib/Helper.service';

@Injectable()
export class DashboardService extends BasicService<config<any>> {
  constructor(protected injector: Injector) {
    super('dashboard/', injector);
  }

  getBusinessDashboard(id: string, query): Observable<any> {
    const endpoint = `dashboard/business/${id}`;

    return this.Get(this.ReturnType, endpoint, query);
  }

  getFilteredTopCusomers(parameter, query): Observable<any> {
    const endpoint = `orders/business/get-top-5-customers?type=${parameter}`;
    return this.Get(this.ReturnType, endpoint, query);
  }


  getPickupWidgetData(query): Observable<any> {
    const endpoint = 'orders/business/get-nugttah-orders-stats';
    return this.Get(this.ReturnType, endpoint, query);
  }


  getBestSellingData(query): Observable<any> {
    const endpoint = 'orders/business/get-best-selling-day';
    return this.Get(this.ReturnType, endpoint, query);
  }

  getTopSellingItemData(query): Observable<any> {
    const endpoint = 'orders/business/get-best-selling-product';
    return this.Get(this.ReturnType, endpoint, query);
  }

  getFavouriteTimeData(query): Observable<any> {
    const endpoint = 'orders/business/get-time-of-day-stats';
    return this.Get(this.ReturnType, endpoint, query);
  }


  getOrdersData(query): Observable<any> {
    const endpoint = 'orders/business/get-orders-graph-stats';
    return this.Get(this.ReturnType, endpoint, query);
  }


  getAgeAndGenderData(query): Observable<any> {
    const endpoint = 'orders/business/get-age-gender-stats';
    return this.Get(this.ReturnType, endpoint, query);
  }


  getTopCardsData(query): Observable<any> {
    const endpoint = 'orders/business/get-documents-count-stats';
    return this.Get(this.ReturnType, endpoint, query);
  }

  getAvgFreq(query): Observable<any> {
    const endpoint = 'orders/business/get-average-orders-per-customer-stats';
    return this.Get(this.ReturnType, endpoint, query);
  }

  getCustomerLifeCycle(query): Observable<any> {
    const endpoint = 'orders/business/get-rfm-stats';
    return this.Get(this.ReturnType, endpoint, query);
  }
}