import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminAuthService } from 'src/app/services/AdminAuth.Service';
import { LoginRoutingModule } from './login-routing.module';

import { LoginRouteComponent } from './loginRoute.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { LoginTemplateComponent } from './login-template/login-template.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LoginRouteComponent,
    ForgetPasswordComponent,
    LoginComponent,
    ResetPasswordComponent,
    LoginTemplateComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    TranslateModule
  ],

  providers: [AdminAuthService]
})
export class LoginModule {}