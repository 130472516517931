import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit, OnChanges {

  lang: any;
  @Input() cardData: any;
  single: any[];
  multi: any[];
  view: any[] = [670, 320];
  // options
  yAxisTicks: number[]; // predefined list of y axis tick values
  yAxis = true;
  xAxis = true; // show or hide the x axis
  tooltipDisabled = false; // show or hide the tooltip
  timeline = true; // display a timeline control under the chart. Only available if a the x scale is linear or time
  yScaleMax = 1000; // the maximum value of the y axis
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  legend = false;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';

  colorScheme = {
    domain: ['#f2bc3d', '#0c425d']
  };

  // line, area
  autoScale = true;

  dataFromAPI: any = [];
  prevData: any = [];
  CurrentData: any = [];


  constructor(public translate: TranslateService, private cookie: CookieService) {
    const multi = [];
    Object.assign(this, { multi });
    translate.onLangChange.subscribe(lang => {
      this.multi = [...this.multi];
      this.translation('');
      this.multi = [
        {
          name: this.translate.instant('dashboard.current'),
          series: this.CurrentData
        },
        {
          name: this.translate.instant('dashboard.previous'),
          series: this.prevData
        }
      ];
    });

  }

  ngOnInit(): void {
    this.lang = this.cookie.get('lang');
    this.translate.onLangChange.subscribe((e: any) => {
      this.lang = e.lang;
    });
    this.sizing();
  }


  onResize(event) {
    this.sizing();
  }


  sizing() {
    if (innerWidth > 0 && innerWidth <= 800) {
      this.view = [420, 320];
    }

    if (innerWidth > 800 && innerWidth <= 1024) {
      this.view = [500, 320];
    }

    if (innerWidth > 1024 && innerWidth <= 1152) {
      this.view = [540, 320];
    }

    if (innerWidth > 1152 && innerWidth <= 1280) {
      this.view = [660, 320];
    }
    if (innerWidth > 1280 && innerWidth <= 1360) {
      this.view = [700, 320];
    }
    if (innerWidth > 1360 && innerWidth <= 1366) {
      this.view = [700, 320];
    }
    if (innerWidth > 1366 && innerWidth <= 1400) {
      this.view = [700, 320];
    }
    if (innerWidth > 1400 && innerWidth <= 1440) {
      this.view = [750, 320];
    }
    if (innerWidth > 1440 && innerWidth <= 1600) {
      this.view = [750, 320];
    }
    if (innerWidth > 1600 && innerWidth <= 1920) {
      this.view = [950, 320];
    }
    if (innerWidth > 1920) {
      this.view = [950, 320];
    }


  }

  onSelect(event) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cardData.currentValue) {
      this.dataFromAPI = [];
      this.prevData = [];
      this.CurrentData = [];
      this.dataFromAPI = changes.cardData.currentValue;
      this.dataFromAPI.forEach(element => {
        this.prevData.push({
          value: element.previousOrdersCount,
          name: element.segment
        });
        this.CurrentData.push({
          value: element.currentOrdersCount,
          name: element.segment
        });
      });

      // set y scale max
      const x = Math.max.apply(Math, this.CurrentData.map((o) => o.value));
      const y = Math.max.apply(Math, this.prevData.map((o) => o.value));
      this.yScaleMax = (x > y) ? (x + 10) : (y + 10);
    }


    this.multi = [
      {
        name: this.translate.instant('dashboard.current'),
        series: this.CurrentData
      },
      {
        name: this.translate.instant('dashboard.previous'),
        series: this.prevData
      }
    ];
  }
  translation = (value) => {
    let newValue = value;
    if (value.includes('AM')) {
      newValue = value.replace('AM', '');
      newValue = newValue + this.translate.instant('AM');
    }
    if (value.includes('PM')) {
      newValue = value.replace('PM', '');
      newValue = newValue + this.translate.instant('PM');
    }
    if (newValue) {
      return this.translate.instant(newValue);
    }
  };
}