import {
  CYRILLIC_PATTERN_MESSAGE,
  EMAIL_PATTERN_MESSAGE,
  unamePattern_MESSAGE,
  PHONE_PATTERN_MESSAGE,
  instagramPattern_MESSAGE,
  twitterPattern_MESSAGE
} from './validation-messages-list';

export const EMAIL_PATTERN = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}$';
export const PHONE_PATTERN = '^((\\5-?)|0)?[0-9]{9}$';
export const CYRILLIC_PATTERN = '^[А-Яа-яЁё\\s]+$';
export const unamePattern = '^+(?:[0-9]●?){6,14}[0-9]$';
export const instagramPattern = '^[a-zA-Z0-9_][a-zA-Z0-9_.]*$';
export const twitterPattern = '^[a-zA-Z0-9_]{1,15}$';

export const PATTERNS_LIST: Array<{ PATTERN: string; MESSAGE: string }> = [
  { PATTERN: EMAIL_PATTERN, MESSAGE: EMAIL_PATTERN_MESSAGE },
  { PATTERN: PHONE_PATTERN, MESSAGE: PHONE_PATTERN_MESSAGE },
  { PATTERN: CYRILLIC_PATTERN, MESSAGE: CYRILLIC_PATTERN_MESSAGE },
  { PATTERN: unamePattern, MESSAGE: unamePattern_MESSAGE },
  { PATTERN: instagramPattern, MESSAGE: instagramPattern_MESSAGE },
  { PATTERN: twitterPattern, MESSAGE: twitterPattern_MESSAGE }
];