import { tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie';
import { NpsService } from './services/nps.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, Renderer2, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { AdminAuthService } from './services/AdminAuth.Service';
import { BranchService } from './services/Branch.Service';
import { environment } from 'src/environments/environment';
import { BusinessService } from './services/business.Service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  lang: any;
  title = 'partner';

  constructor(
    private _renderer2: Renderer2,
    public translate: TranslateService,
    private cookieService: CookieService,
    private branchService: BranchService,
    private businessService: BusinessService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    const generalPaymentOptionsScript = this._renderer2.createElement('script');
    generalPaymentOptionsScript.text = `
    var wpwlOptions = {
      style: "plain",
      locale: "en",

      applePay: {
        displayName: "Nugttah",
        total: { label: "Nugttah" },
        style: "black",
      },
      onReady: function () {
      if (wpwlOptions.locale == "ar") {
        (".wpwl-group").style.direction = "ltr";
        (".wpwl-control-cardNumber").style.direction = "ltr";
        (".wpwl-control-cardNumber").style.textAlign  = "left !important";
        (".wpwl-group").style.right = "200px";
        (".wpwl-brand-card").style.right = "auto !important";
        (".wpwl-brand-card").style.left = "4px !important";

      }
      }
    };
      `;
    this._renderer2.appendChild(this._document.body, generalPaymentOptionsScript);
  }
  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('User'));
    this.branchService.getBusinessData(user.id).subscribe(Config => {
      const bs = Config.body.data;
      const win = window as any;
      if (environment.production) {
        win.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'in9mh42l',
          user_id: bs._id,
          name: bs.firstname ? `${bs.firstname} ${bs.lastname}` : bs.name,
          email: bs.email,
          phone: bs.phone,
          brand: bs.brand.name.ar
        });
      }
      const user = JSON.parse(localStorage.getItem('User'));
      const { image_path } = Config.body.data;
      user.image_path = image_path;
      localStorage.setItem('User', JSON.stringify(user));
    });
    this.cookieService.get('lang');
    const isAgent = localStorage.getItem('isAgent');
    if (!isAgent) {
      this.businessService.logActivity().subscribe();
    }
  }
}