import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder
  ) {}

  form = this.fb.group(
    {
      password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(40)]],
      Conpassword: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(40)]]
    }
  );

  content = {
    header: 'Enter New Password.'
  };

  ngOnInit() {}
}