import { NgModule } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { RatingModule } from 'ngx-bootstrap/rating';
import { ModalModule } from 'ngx-bootstrap/modal';




const exportedModules = [
  PaginationModule,
  CollapseModule,
  RatingModule,
  ModalModule
];

@NgModule({
  imports: [...exportedModules, PaginationModule.forRoot(), CollapseModule.forRoot(), RatingModule.forRoot(), ModalModule.forRoot()],
  declarations: [],
  providers: [],
  exports: [exportedModules]
})
export class NgxBootstrapImportsModule {}