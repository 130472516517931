import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Injector, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GenricService } from './Generic.service';
import { Helper } from './Helper.service';
export class BasicService<T> extends GenricService {
  BaseEndPoint: string;

  protected ReturnType: T;

  public _fb: UntypedFormBuilder;

  helper: Helper;

  protected toastr: ToastrService;

  constructor(endpoint: string, protected injector: Injector) {
    super(injector);

    this.BaseEndPoint = endpoint;
    this.helper = new Helper();
    this._fb = injector.get(UntypedFormBuilder);
    this.toastr = injector.get(ToastrService);
  }
  /** List All */

  GetAll(filter) {
    return this.Get(this.ReturnType, this.BaseEndPoint, filter);
  }

  /** Get One Element */
  GetOne(id: Number, filter = ''): Observable<any> {
    return this.Get(this.ReturnType, `${this.BaseEndPoint}${id}`, filter);
  }
  /**  Add On */
  AddOneWithUpload(form: UntypedFormGroup) {

    const formData = Helper.GetFormDataFromFormGroup(form);

    const endpoint = `${this.BaseEndPoint}add/`;
    return this.Post(this.ReturnType, endpoint, formData);
  }

  /**  Add On without upload */
  addOne(form: UntypedFormGroup) {
    // return true;
    const Data = Helper.GetFormDataFromFormGroup(form);
    return this.Post(this.ReturnType, this.BaseEndPoint, Data);
  }

  /**  Update One */
  UpdateOne(id: string, form: UntypedFormGroup) {
    const Data = Helper.GetFormDataFromFormGroup(form);
    (JSON.stringify(Data));

    /** form.value because it not contain file or multipart */
    const endpoint = `${this.BaseEndPoint}${id}`;
    return this.Put(this.ReturnType, endpoint, Data);
  }


  UpdateNotForm(id: string, Data: any) {
    const endpoint = `${this.BaseEndPoint}${id}`;
    return this.Put(this.ReturnType, endpoint, Data);
  }

  /**  Delete One */
  DeleteOne(id: string) {
    const endpoint = `${this.BaseEndPoint}${id}`;
    return this.Delete(this.ReturnType, endpoint);
  }

  changeStatus(id, status) {
    const data = {
      action: 'block',
      status
    };
    const endpoint = `${this.BaseEndPoint}${id}/change_profile_status`;
    return this.Put(this.ReturnType, endpoint, data);
  }

  toastrSuccess(msg) {
    this.toastr.success(msg);
  }

  inializeForm(fieldValues): UntypedFormGroup {
    return this._fb.group(fieldValues);
  }

  toastrError(msg) {
    this.toastr.error(msg);
  }

  toastrInfo(msg) {
    this.toastr.info(msg, 'Info');
  }

  toastrWarn(msg) {
    this.toastr.warning(msg, 'Warn');
  }
}