import { Injectable, Injector } from '@angular/core';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { BasicService } from './lib/Basic.Service';

import { config, cashier } from '../models';
import {
  PHONE_PATTERN,
  EMAIL_PATTERN,
  instagramPattern,
  twitterPattern
} from '../reusable-components/form-fields/validation-errors/validation-patterns-list';

@Injectable()
export class BranchService extends BasicService<config<any>> {
  constructor(protected injector: Injector) {
    super('branches/', injector);
  }

  addFileds = {
    business: ['', [Validators.required]],
    photo: ['', [Validators.required]],
    photos: this._fb.array([]),
    name: this._fb.group({
      en: ['', [Validators.required, Validators.minLength(2)]],
      ar: ['', [Validators.required, Validators.minLength(2)]]
    }),
    desc: this._fb.group({
      en: [''],
      ar: ['']
    }),

    email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    phone: ['', [Validators.required, Validators.pattern(PHONE_PATTERN)]],
    dial_code: ['966', [Validators.required]],
    country_code: ['sa', [Validators.required]],
    accepts_online_orders: [false],
    pickup_promising_time: ['', [Validators.required, Validators.min(1)]],
    address: ['', [Validators.required]],
    country: ['0', [Validators.required, Validators.min(1)]],
    city: ['0', [Validators.required, Validators.min(1)]],
    branch_category: ['0', [Validators.required, Validators.min(1)]],
    brand: ['0', [Validators.required, Validators.min(1)]],
    branch_code: ['', [Validators.required]],
    longitude: [46.67529569999999, [Validators.required]],
    latitude: [24.7135517, [Validators.required]],
    openingTime: this._fb.array([
      this._fb.group({
        day: 0,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 1,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 2,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 3,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 4,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 5,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 6,
        periods: this._fb.array([])
      })
    ]),
    social_links: this._fb.group({

      instagram: ['', [Validators.pattern(instagramPattern)]],
      twitter: ['', [Validators.maxLength(15), Validators.pattern(twitterPattern)]]
    })
  };

  updateFileds = {
    business: ['', [Validators.required]],
    photo: [''],
    photos: this._fb.array([]),
    name: this._fb.group({
      en: ['', [Validators.required, Validators.minLength(2)]],
      ar: ['', [Validators.required, Validators.minLength(2)]]
    }),
    desc: this._fb.group({
      en: [''],
      ar: ['']
    }),
    email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    phone: ['', [Validators.required, Validators.pattern(PHONE_PATTERN)]],
    dial_code: ['', [Validators.required]],
    country_code: ['SA', [Validators.required]],
    address: ['', [Validators.required]],
    country: ['0', [Validators.required, Validators.min(1)]],
    accepts_online_orders: [false],
    pickup_promising_time: ['', [Validators.required, Validators.min(1)]],
    city: ['0', [Validators.required, Validators.min(1)]],
    branch_category: ['0', [Validators.required, Validators.min(1)]],
    brand: ['0', [Validators.required, Validators.min(1)]],
    branch_code: ['', [Validators.required]],
    longitude: [46.67529569999999, [Validators.required]],
    latitude: [24.7135517, [Validators.required]],

    openingTime: this._fb.array([
      this._fb.group({
        day: 0,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 1,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 2,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 3,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 4,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 5,
        periods: this._fb.array([])
      }),
      this._fb.group({
        day: 6,
        periods: this._fb.array([])
      })
    ]),
    social_links: this._fb.group({

      instagram: ['', [Validators.pattern(instagramPattern)]],
      twitter: ['', [Validators.maxLength(15), Validators.pattern(twitterPattern)]]
    })
  };

  openingTimesList = [
    {
      dayValue: 0,
      dayName: 'Sunday'
    },
    {
      dayValue: 1,
      dayName: 'Monday'
    },
    {
      dayValue: 2,
      dayName: 'Tuesday'
    },
    {
      dayValue: 3,
      dayName: 'Wednesday'
    },
    {
      dayValue: 4,
      dayName: 'Thursday'
    },
    {
      dayValue: 5,
      dayName: 'Friday'
    },
    {
      dayValue: 6,
      dayName: 'Saturday'
    }
  ];

  tabs = [
    {
      name: 'Info',
      icon: 'edit'
    },

    {
      name: 'Location',
      icon: 'my_location'
    },
    {
      name: 'Work Time',
      icon: 'access_time'
    },
    {
      name: 'Slider Photos',
      icon: 'image'
    }
  ];

  GetBusinessBranches(filter) {
    const endpoint = `${this.BaseEndPoint}business_list`;
    return this.Get(this.ReturnType, endpoint, filter);
  }

  DeletePhoto(branchId: string, url: string) {
    const endpoint = `${this.BaseEndPoint}${branchId}/photo?url=${url}`;
    return this.Delete(this.ReturnType, endpoint);
  }


  getBusinessData(id: string) {
    const endpoint = `businesses/${id}`;
    return this.Get(this.ReturnType, endpoint, '');
  }
}