import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminAuthService } from 'src/app/services/AdminAuth.Service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  lang: any;

  constructor(
    private service: AdminAuthService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {}

  submitted = false;

  form = this.fb.group(
    {
      email: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(40), Validators.email]
      ]
    }
  );

  content = {
    header: 'Enter your Email',
    link: 'Login',
    linkRoute: '/login'
  };

  ngOnInit() {}

  onSubmit() {
    this.service.forgetPassword(this.form).subscribe(res => {
      if (res.body.data) {
        this.service.toastrSuccess(this.lang === 'en' ? res.body.message.en : res.body.message.ar);
        this.submitted = true;
        setTimeout(() => this.router.navigate(['../'], { relativeTo: this.route }), 100);
      } else {
        this.service.toastrError(this.translate.instant('toastrMsgs.errorMsgs.failedUpdatePass'));
      }
    });
  }
}