import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdminAuthService } from 'src/app/services/AdminAuth.Service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  FlashMessage = '';
  realLogin = false;
  lang: any;
  isAgent = true;
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AdminAuthService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private cookie: CookieService
  ) { }
  form = this.fb.group({
    email: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(40), Validators.email]],
    password: ['', [Validators.required, Validators.maxLength(40)]]
  });

  content = {
    header: 'Please login to continue.',
    link: 'Forget password?',
    linkRoute: '/login/forget-password'
  };

  ngOnInit() {
    this.lang = this.cookie.get('lang');
    if (!this.lang) {
      this.cookie.put('lang', 'en');
    }
    this.activatedRoute.queryParams.subscribe(params => {
      const accessToken = params['accesstoken'];
      const image = params['image'];
      const brand = params['brand'];
      const log = params['log'];
      const adminId = params['adminId'];
      if (accessToken) {
        this.authService.LogOut();
        this.authService.fakeLogin(accessToken, image, brand);
        window.location.reload();
        localStorage.setItem('isAgent', JSON.stringify(this.isAgent));
        localStorage.setItem('adminId', adminId);
        return;
      }
      this.realLogin = true;
    });
  }

  onsubmit() {
    const formdata = this.form;
    this.FlashMessage = '';

    this.authService.LogIn(formdata).subscribe(
      res => {
        if (res.body.data && res.body.statusCode === 200) {
          this.authService.toastrSuccess(this.lang === 'en' ? res.body.message.en : res.body.message.ar);
          window.location.reload();
        }
        if (res.body.message) {
          this.authService.toastrError(this.lang === 'en' ? res.body.message.en : res.body.message.ar);
          return null;
        }
        this.authService.toastrError(this.translate.instant('toastrMsgs.errorMsgs.somethingWentWrong'));
        return null;
      }
    );
  }
}