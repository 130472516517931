import { AfterViewInit, Component, OnInit, TemplateRef } from '@angular/core';
import { BranchService } from 'src/app/services/Branch.Service';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DashboardService } from 'src/app/services/dashboard.Service';
import { environment } from 'src/environments/environment';
import { BranchesService } from './../../services/branches.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, AfterViewInit {
  dashboard_has_no_data: boolean = false;
  maxDate: any;
  donutChartData: any = [];
  lang: any;
  // Filter by
  bsRangeValue: Date[];
  selectedPeriod = 'today';
  periodName = 'Today';
  periodNameDropDown = this.periodName;
  startDate: string | number;
  endDate: string | number;


  // Date Configuration
  bsConfig: Partial<BsDatepickerConfig>;
  periodsList = [
    { name: 'Today', value: 'today' },
    { name: 'This Week', value: 'week' },
    { name: 'Last 28 days', value: 'month' },
    { name: 'This Year', value: 'year' }
  ];
  // EOF Filter by

  // Business Cards
  businessCards: any = {
    TotalVouchers: 0,
    businessCustomers: 0,
    expiredVouchers: 0,
    period: 'This Week',
    totalTranscation: 0,
    unUsedNotExpiredVouchers: 0,
    unUsedVouchers: 0,
    usedVouchers: 0
  };
  // EOF Business Cards

  // Orders
  linechart: any;
  orders_view_by: any;
  orders_view_list: any;
  orderQuery: any = {};
  orders: any;
  // EOF Orders

  // Best Selling Day
  bestSellingQuery: any;
  bestSellingDay: any;
  bestSellingDayName: any;

  // Top Selling Item
  bestSellingItemQuery: any;


  filterTopSelling: any = 'unitsold';
  bestSelling3yNumber: any = [];
  bestSelling3ByRevenue: any = [];

  filterTop10Selling: any = 'unitsold';
  bestSelling10ByNumber: any = [];
  bestSelling10ByRevenue: any = [];




  // Favourite
  favQuery: any;
  favMorning: any;
  favAfternoon: any;
  favEvining: any;
  fovouritTime: any;

  // Pickup
  pickupWidgetData_query: any;
  pickuCount: any = 0;
  pickuRevenue: any = 0;
  averageOrdersPerCustomer: any = 0;
  averageTransactions: any = 0;
  top_selling_product: any = '';

  // top 5 customers
  customer_filter_paramater: any = 'all';
  topNew5customers: any;
  topNew5customers_all: any;
  topNew5customers_nugttah_order: any;
  top_5_customers_query: any;
  top_5_filter: any = 'revenue';

  // age and gender
  ageGenderQuery: any;
  ageGenderData: any = [];
  maleCount: number = 0;
  femaleCount: number = 0;
  maleRatio: number = 0;
  femaleRatio: number = 0;
  unknownCount: number = 0;
  unknownRatio: any = 0;
  totalMaleFemale: any = 0;



  // hide pickup if not approved
  isPickupApprovedByNugttah: boolean = false;
  businessId: any;

  // filter by branches
  branchesList: any;
  branchSelect: any = '';
  selectedPeriod2: any = {
    name: 'This Week',
    value: 'week'
  };

  // customerAverageFrequency
  customerAverageFrequency: any = 0;
  avgFrequencyQuery: any;

  // customerLifeCycle
  customerLifeCycle: any = [];
  customerLifeCycleQuery: any;
  loyalRatio: any = 0;
  promisingRatio: any = 0;
  activeRatio: any = 0;
  newRatio: any = 0;
  slippingRatio: any = 0;
  lostRatio: any = 0;
  totalClc: any = 0;

  // top 3 cards
  topCardsQuery: any;
  topCardsTransactions: any;
  topCardsCustomers: any;
  topCardsCreatedVouchers: any;
  topCardsUsedVouchers: any;
  topCardData: any;


  // Branches List
  branchesParam: any;
  brandId: any;
  branchList: any = [];

  // modal
  modalRef: any;
  ordersLoading = false;
  topCardDataLoding = false;
  ageGenderDataLoading = false;
  favouriteTimeLoading = false;
  bestSellingDayLoading = false;
  donutChartDataLoading = false;
  customerLifeCycleLoading = false;
  bestSelling3yNumberLoading = false;
  topNew5customersAllLoading = false;
  ordersCreatedByNugttahLoading = false;
  customerAverageFrequencyLoading = false;
  constructor(
    private service: DashboardService,
    private service2: BranchService,
    private branchesService: BranchesService,
    private modalService: BsModalService,
    public translate: TranslateService,
    private cookie: CookieService
  ) {

  }

  ngOnInit() {
    this.lang = this.cookie.get('lang');
    this.translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
    });
    this.maxDate = new Date();

    this.orders_view_list = [
      {
        vlaue: 'today',
        text: 'Hourly'
      },
      {
        vlaue: 'week',
        text: 'Weekly'
      }
    ];
    this.orders_view_by = 'week';


    const user = JSON.parse(localStorage.getItem('User'));
    this.brandId = user.brand;
    this.businessId = user.id;


    this.getBusinessBranches();
    this.getPageData();
  }

  ngAfterViewInit() {


    this.service2.getBusinessData(this.businessId).subscribe(Config => {
      this.isPickupApprovedByNugttah = Config.body.data.brand.acceptsOnlineOrders;
      const user = JSON.parse(localStorage.getItem('User'));
      const { image_path } = Config.body.data;
      user.image_path = image_path;
      localStorage.setItem('User', JSON.stringify(user));
    });


  }

  getPageData() {
    this.getOrders();
    this.getBestSelling();
    this.getTopSellingItem();
    this.getFavouriteTime();
    this.pickupWidgetData();
    this.getTop5Customers();
    this.donutChartData = [];
    this.ageGenderData = [];
    this.customerLifeCycle = [];
    this.getAgeAndGender();
    this.getTop4Cards();
    this.getGetAvgFrequency();
    this.getCustomerLifeCycle();
  }

  selectPeriod() {

    this.selectedPeriod2 = this.selectedPeriod;
    if (this.selectedPeriod !== 'today') {
      this.periodName = `This ${this.selectedPeriod}`;
    } else {
      this.periodName = this.selectedPeriod;
    }
    this.periodNameDropDown = this.selectedPeriod;
    this.getOrderViewList(this.selectedPeriod);
    this.getPageData();

  }

  getOrders() {
    this.orderQuery = {
      period: this.selectedPeriod,
      segmentationPeriod: this.orders_view_by
    };
    if (this.branchSelect !== '') {
      this.orderQuery = Object.assign(this.orderQuery, { branch: this.branchSelect });
    }
    // get data
    this.ordersLoading = true;
    this.service.getOrdersData(this.orderQuery).subscribe(res => {
      this.orders = res.body.data.segments;
      if (this.orders.length === 0) {
        this.dashboard_has_no_data = true;
      }
      this.ordersLoading = false;
    });
  }

  getOrderViewList(period: string) {
    this.orders_view_list = [];
    if (period === 'today') {
      this.orders_view_list = [
        {
          vlaue: 'today',
          text: 'Hourly'
        }
      ];
      this.orders_view_by = 'today';
    }

    if (period === 'week') {
      this.orders_view_list = [
        {
          vlaue: 'today',
          text: 'Hourly'
        },
        {
          vlaue: 'week',
          text: 'Weekly'
        }
      ];
      this.orders_view_by = 'week';
    }

    if (period === 'month') {
      this.orders_view_list = [
        {
          vlaue: 'today',
          text: 'Hourly'
        },
        {
          vlaue: 'week',
          text: 'Weekly'
        },
        {
          vlaue: 'month',
          text: 'Monthly'
        }
      ];
      this.orders_view_by = 'month';
    }

    if (period === 'year') {
      this.orders_view_list = [
        {
          vlaue: 'today',
          text: 'Hourly'
        },
        {
          vlaue: 'week',
          text: 'Weekly'
        },
        {
          vlaue: 'month',
          text: 'Monthly'
        },
        {
          vlaue: 'year',
          text: 'Yearly'
        }
      ];
      this.orders_view_by = 'year';
    }
  }

  getBestSelling() {
    this.bestSellingDayLoading = true;
    this.bestSellingQuery = {
      period: this.selectedPeriod
    };
    if (this.branchSelect !== '') {
      this.bestSellingQuery = Object.assign(this.bestSellingQuery, { branch: this.branchSelect });
    }
    this.service.getBestSellingData(this.bestSellingQuery).subscribe(res => {
      this.bestSellingDay = res.body.data.bestSellingDay;
      this.bestSellingDayName = moment(this.bestSellingDay).format('dddd');
      this.bestSellingDayLoading = false;
    });
  }



  getTopSellingItem() {
    this.bestSelling3yNumberLoading = true;
    this.bestSellingItemQuery = {
      period: this.selectedPeriod
    };
    if (this.branchSelect !== '') {
      this.bestSellingItemQuery = Object.assign(this.bestSellingItemQuery, { branch: this.branchSelect });
    }

    // get data
    this.service.getTopSellingItemData(this.bestSellingItemQuery).subscribe(res => {
      this.bestSelling10ByNumber = res.body.data[0].bestSelling10ByNumber;
      this.bestSelling10ByRevenue = res.body.data[0].bestSelling10ByRevenue;

      let x = 1;
      res.body.data[0].bestSelling10ByNumber.forEach(element => {
        if (x === 3) {
          return false;
        }
        this.bestSelling3yNumber.push(element);
        x++;
      });

      let y = 1;
      res.body.data[0].bestSelling10ByRevenue.forEach(element => {
        if (y === 3) {
          return false;
        }
        this.bestSelling3ByRevenue.push(element);
        y++;
      });
      this.bestSelling3yNumberLoading = false;
    });
  }

  filterTopSellingItem() {
  }
  getFavouriteTime() {
    this.favouriteTimeLoading = true;
    this.favQuery = {
      period: this.selectedPeriod
    };
    if (this.branchSelect !== '') {
      this.favQuery = Object.assign(this.favQuery, { branch: this.branchSelect });
    }
    this.service.getFavouriteTimeData(this.favQuery).subscribe(res => {
      this.favMorning = this.getTimeOrdersPercentage(res.body.data.durations, 'Morning');
      this.favAfternoon = this.getTimeOrdersPercentage(res.body.data.durations, 'Afternoon');
      this.favEvining = this.getTimeOrdersPercentage(res.body.data.durations, 'Evening');
      this.favouriteTimeLoading = false;
    });
  }

  getTimeOrdersPercentage(durations, timeLabel: string) {
    const timeObj = durations
      ? durations.find((x) => x.timeOfDay === timeLabel)
      : {};
    return timeObj && timeObj.percentage ? timeObj.percentage : 0;
  }

  cardDateArr = ['today', 'week', 'month', 'year'];

  pickupWidgetData() {
    this.ordersCreatedByNugttahLoading = true;
    this.pickupWidgetData_query = {
      period: this.selectedPeriod
    };
    if (this.branchSelect !== '') {
      this.pickupWidgetData_query = Object.assign(this.pickupWidgetData_query, { branch: this.branchSelect });
    }
    this.service.getPickupWidgetData(this.pickupWidgetData_query).subscribe(res => {
      if (res.body.data.ordersCount) {
        this.pickuCount = res.body.data.ordersCount;
      }
      if (res.body.data.totalRevenue) {
        this.pickuRevenue = res.body.data.totalRevenue.toFixed(0);
      }
      if (res.body.data.top_selling_product) {
        this.top_selling_product = res.body.data.top_selling_product;
      }
      if (res.body.data.averageOrdersPerCustomer) {
        this.averageOrdersPerCustomer = res.body.data.averageOrdersPerCustomer;
      }
      if (res.body.data.averageTransactions) {
        this.averageTransactions = res.body.data.averageTransactions;
      }
      this.ordersCreatedByNugttahLoading = false;
    });

  }



  sortTopcustomer() {
    this.getTop5Customers();
  }

  getTop5Customers() {
    this.top_5_customers_query = {
      period: this.selectedPeriod,
      sortBy: this.top_5_filter
    };
    if (this.branchSelect !== '') {
      this.top_5_customers_query = Object.assign(this.top_5_customers_query, { branch: this.branchSelect });
    }
    this.topNew5customersAllLoading = true;
    this.service.getFilteredTopCusomers('all', this.top_5_customers_query).subscribe(res => {
      this.topNew5customers_all = res.body.data;
      this.topNew5customers_all.map(item => {
        item.totalPaidAmount = Math.round(item.totalPaidAmount);
        item.name = item.name === ' ' ? '' : item.name;
      });
      this.topNew5customersAllLoading = false;
    });
    this.topNew5customersAllLoading = true;
    this.service.getFilteredTopCusomers('nugttah_order', this.top_5_customers_query).subscribe(res => {
      this.topNew5customers_nugttah_order = res.body.data;
      this.topNew5customers_nugttah_order.map(item => {
        item.totalPaidAmount = Math.round(item.totalPaidAmount);
      });
      this.topNew5customers = this.topNew5customers_nugttah_order;
      this.topNew5customersAllLoading = false;
    });

  }

  filter_top_5_customers(paramter: any) {
    this.customer_filter_paramater = paramter;
  }

  getAgeAndGender() {
    this.ageGenderQuery = {
      period: this.selectedPeriod
    };
    if (this.branchSelect !== '') {
      this.ageGenderQuery = Object.assign(this.ageGenderQuery, { branch: this.branchSelect });
    }

    // get data
    this.donutChartDataLoading = true;
    this.ageGenderDataLoading = true;
    this.service.getAgeAndGenderData(this.ageGenderQuery).subscribe(res => {
      this.maleCount = 0;
      this.femaleCount = 0;
      this.unknownCount = 0;
      this.ageGenderData = res.body.data;
      // calculate total male / female  / unknown
      res.body.data.forEach(element => {
        this.maleCount = this.maleCount + element.maleCustomersCount;
        this.femaleCount = this.femaleCount + element.femaleCustomersCount;
        this.unknownCount = this.unknownCount + element.undefinedGenderCustomersCount;
      });
      this.totalMaleFemale = this.maleCount + this.femaleCount + this.unknownCount;
      if (this.totalMaleFemale !== 0) {
        this.maleRatio = Math.round((this.maleCount / this.totalMaleFemale) * 100);
        this.femaleRatio = Math.round((this.femaleCount / this.totalMaleFemale) * 100);
        this.unknownRatio = Math.round((this.unknownCount / this.totalMaleFemale) * 100);
      } else {
        this.maleRatio = 0;
        this.femaleRatio = 0;
        this.unknownRatio = 0;
      }

      this.donutChartData = [
        {
          label: 'Female',
          value: this.femaleCount,
          color: '#efaa43'
        },
        {
          label: 'Male',
          value: this.maleCount,
          color: '#5cad8d'
        },
        {
          label: 'not-defined',
          value: this.unknownCount,
          color: '#7e7e7e'
        }
      ];
      this.ageGenderDataLoading = false;
      this.donutChartDataLoading = false;
    });
  }


  getTop4Cards() {
    this.topCardsQuery = {
      period: this.selectedPeriod
    };
    if (this.branchSelect !== '') {
      this.topCardsQuery = Object.assign(this.topCardsQuery, { branch: this.branchSelect });
    }



    // get data
    this.topCardDataLoding = true;
    this.service.getTopCardsData(this.topCardsQuery).subscribe(res => {
      this.topCardsTransactions = res.body.data.orders_count;
      this.topCardsCustomers = res.body.data.customers_count;
      this.topCardsCreatedVouchers = res.body.data.created_vouchers;
      this.topCardsUsedVouchers = res.body.data.used_vouchers;

      this.topCardData = {
        transactions: this.topCardsTransactions,
        customers: this.topCardsCustomers,
        createdVouchers: this.topCardsCreatedVouchers,
        usedVouchers: this.topCardsUsedVouchers,
        period: this.periodName
      };
      this.topCardDataLoding = false;
    });
  }

  getGetAvgFrequency() {
    this.customerAverageFrequencyLoading = true;
    this.avgFrequencyQuery = {
      period: this.selectedPeriod
    };
    if (this.branchSelect !== '') {
      this.avgFrequencyQuery = Object.assign(this.avgFrequencyQuery, { branch: this.branchSelect });
    }

    // get data
    this.service.getAvgFreq(this.avgFrequencyQuery).subscribe(res => {
      this.customerAverageFrequency = res.body.data.averageOrdersPerCustomer;
      this.customerAverageFrequencyLoading = false;
    });


  }


  getCustomerLifeCycle() {
    this.customerLifeCycleQuery = {
      period: this.selectedPeriod
    };
    if (this.branchSelect !== '') {
      this.customerLifeCycleQuery = Object.assign(this.customerLifeCycleQuery, { branch: this.branchSelect });
    }

    // get data
    this.customerLifeCycleLoading = true;
    this.service.getCustomerLifeCycle(this.customerLifeCycleQuery).subscribe(res => {
      this.customerLifeCycle = res.body.data.RFMStats;
      this.totalClc = this.customerLifeCycle.bestCount + this.customerLifeCycle.lostCount
        + this.customerLifeCycle.loyalCount + this.customerLifeCycle.newCount
        + this.customerLifeCycle.promisingCount + this.customerLifeCycle.riskCount;
      if (this.totalClc !== 0) {
        this.loyalRatio = Math.round((this.customerLifeCycle.loyalCount / this.totalClc) * 100);
        this.promisingRatio = Math.round((this.customerLifeCycle.promisingCount / this.totalClc) * 100);
        this.activeRatio = Math.round((this.customerLifeCycle.bestCount / this.totalClc) * 100);
        this.newRatio = Math.round((this.customerLifeCycle.newCount / this.totalClc) * 100);
        this.slippingRatio = Math.round((this.customerLifeCycle.riskCount / this.totalClc) * 100);
        this.lostRatio = Math.round((this.customerLifeCycle.lostCount / this.totalClc) * 100);
      } else {
        this.loyalRatio = 0;
        this.promisingRatio = 0;
        this.activeRatio = 0;
        this.newRatio = 0;
        this.slippingRatio = 0;
        this.lostRatio = 0;
      }
      this.customerLifeCycleLoading = false;
    }, () => {
      this.customerLifeCycleLoading = false;
    }, () => {
      this.customerLifeCycleLoading = false;
    });
  }



  getBusinessBranches() {
    this.branchesParam = {
      brand: this.brandId,
      page_no: 1,
      page_size: 10000
    };

    this.branchesService.getAllBranchesData(this.branchesParam).subscribe(result => {
      this.branchList = result.body.data.list.map(x => ({
        _id: x._id,
        name_en: `${x.name.en} `,
        name_ar: `${x.name.ar} `
      }));


    });
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);

  }

}