import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-login-template',
  templateUrl: './login-template.component.html',
  styleUrls: ['./login-template.component.scss']
})
export class LoginTemplateComponent implements OnInit {
  @Input() content;

  @Input() form: UntypedFormGroup;

  @Input() fields: object[];

  constructor() {}

  ngOnInit() {}
}