import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-multipie',
  templateUrl: './multipie.component.html',
  styleUrls: ['./multipie.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipieComponent implements OnInit, OnChanges {
  @Input() femaleCount: any;

  @Input() maleCount: any;

  single: any[];

  multi: any[];


  view: any[];

  // 400 170
  colorScheme = {
    domain: ['#a8d2be', '#f9ae4e']
  };

  tooltipDisabled = false;

  constructor() {
    Object.assign(this, { single: this.single, multi: this.multi });
  }

  ngOnInit(): void {
    this.sizing();
  }

  onSelect(event) {}

  onResize(event) {
    this.sizing();
  }

  sizing() {
    if (innerWidth <= 576) {
      this.view = [innerWidth / 2, 170];
    } else if (innerWidth <= 1500) {
      this.view = [innerWidth / 3.5, 170];
    } else if (innerWidth <= 1800) {
      this.view = [innerWidth / 3.9, 170];
    } else {
      this.view = [innerWidth / 4.6, 170];
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.femaleCount.currentValue || changes.maleCount.currentValue) {

      this.single = [
        {
          name: 'females',
          value: changes.femaleCount.currentValue || 0
        },
        {
          name: 'males',
          value: changes.maleCount.currentValue || 0
        }
      ];
    }
  }
}