import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @Input() cardDataFemal: any;

  @Input() cardDataMamel: any;

  @Input() ageGenderData: any;
  ageGenderDataLoop: any;
  single: any[];

  multi: any = [];

  male: number;

  female;
  xScaleMax = 100;


  // options
  showXAxis = true;

  showYAxis = true;

  gradient = false;

  showLegend = false;

  showXAxisLabel = false;

  xAxisLabel = 'Country';

  showYAxisLabel = false;

  yAxisLabel = 'Population';

  barPadding = 0;
  view: any[] = [430, 240];
  xAxisTicks = [];

  colorScheme = {
    domain: ['#a8d2be', '#f9ae4e']
  };

  constructor() {
    Object.assign(this, { multi: this.multi });
  }

  ngOnInit(): void {
    this.ageGenderData.forEach(element => {
      this.multi.push({
        name: element.ageRange,
        series: [
          {
            name: 'male',
            value: element.maleCustomersCount
          },
          {
            name: 'female',
            value: element.femaleCustomersCount
          }
        ]
      });

    });

    this.size();
  }

  onSelect(event) {}

  onResize(event) {
    this.size();
  }

  size() {

    if (innerWidth > 0 && innerWidth <= 800) {
      this.view = [260, 260];
    }

    if (innerWidth > 800 && innerWidth <= 1024) {
      this.view = [350, 260];
    }

    if (innerWidth > 1024 && innerWidth <= 1152) {
      this.view = [390, 260];
    }

    if (innerWidth > 1152 && innerWidth <= 1280) {
      this.view = [420, 260];
    }
    if (innerWidth > 1280 && innerWidth <= 1360) {
      this.view = [470, 260];
    }
    if (innerWidth > 1360 && innerWidth <= 1366) {
      this.view = [470, 260];
    }
    if (innerWidth > 1366 && innerWidth <= 1400) {
      this.view = [260, 260];
    }
    if (innerWidth > 1400 && innerWidth <= 1440) {
      this.view = [800, 260];
    }
    if (innerWidth > 1440 && innerWidth <= 1600) {
      this.view = [260, 260];
    }
    if (innerWidth > 1600 && innerWidth <= 1920) {
      this.view = [260, 260];
    }
    if (innerWidth > 1920) {
      this.view = [260, 260];
    }

  }


}