import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NpsService } from 'src/app/services/nps.service';
import { BrandService } from 'src/app/services/Brand.Service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.scss'],
  animations: [
    trigger('fade', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible => hidden', animate('1500ms ease-out')),
      transition('hidden => visible', animate('1500ms ease-in'))
    ])
  ]
})
export class NpsComponent implements OnInit {
  loading: boolean;
  npsForm: FormGroup;
  selectedRating: number;
  modalRef: NgbModalRef | undefined;
  submitted: boolean = false;
  @ViewChild('npsModalTemplate', { static: true }) npsModalTemplate!: TemplateRef<any>;
  emojis = ['../../../assets/sad-but-relieved.svg', '../../../assets/worried-face.svg', '../../../assets/neutral-face.svg', '../../../assets/smiling-face.svg', '../../../assets/partying-face.svg'];
  showNpsContainer = true;

  constructor(
    private fb: FormBuilder,
    private npsService: NpsService,
    private brandService: BrandService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  setRating(index: number) {
    this.selectedRating = (index + 1) * 2;
    this.npsForm.patchValue({ score: this.selectedRating });
    console.log('rating', this.selectedRating);
    this.submit();
  }

  addClass(event) {
    const buttons = document.querySelectorAll('.col.nps-container button');
    buttons.forEach(button => {
      button.classList.remove('active');
    });
    const clickedButton = event.target;
    clickedButton.classList.add('active');
  }

  initializeForm() {
    this.npsForm = this.fb.group({
      score: ['', [Validators.required, Validators.min(1)]],
      comment: ['', Validators.minLength(2)]
    });
  }


  closeNpsContainer(): void {
    this.showNpsContainer = false;
    const npsElem: any = document.getElementsByClassName('nps-section-footer');
    setTimeout(() => {
      npsElem[0].style.display = 'none';
    }, 1000);
  }


  async submit() {
    const data = this.npsForm.value;
    !data.comment && delete data.comment;
    const response = await this.npsService.rating(data).toPromise();
    if (response) {
      console.log(response);
      if (response.statusCode === 200) {
        this.brandService.toastrSuccess(response.message.en);
        this.submitted = true;
        setTimeout(() => {
          this.closeNpsContainer();
        }, 2000);
      } else {
        this.brandService.toastrError(response.message.en);
        this.submitted = false;
      }
    }
    this.modalRef.close();
  }
}