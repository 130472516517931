import { Component, Input, OnChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnChanges {
  @Input() toggleSpinner: boolean;
  spinnerIndex: number;
  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private cookieService: CookieService
  ) { }
  lang: any;
  spinnerTypesList = [
    'ball-8bits',
    'ball-atom',
    'ball-beat',
    'ball-circus',
    'ball-climbing-dot',
    'ball-clip-rotate',
    'ball-clip-rotate-multiple',
    'ball-clip-rotate-pulse',
    'ball-elastic-dots',
    'ball-fall',
    'ball-fussion',
    'ball-grid-beat',
    'ball-grid-pulse',
    'ball-newton-cradle',
    'ball-pulse',
    'ball-pulse-rise',
    'ball-pulse-sync',
    'ball-rotate',
    'ball-running-dots',
    'ball-scale',
    'ball-scale-multiple',
    'ball-scale-pulse',
    'ball-scale-ripple',
    'ball-scale-ripple-multiple',
    'ball-spin',
    'ball-spin-clockwise',
    'ball-spin-clockwise-fade',
    'ball-spin-clockwise-fade-rotating',
    'ball-spin-fade',
    'ball-spin-fade-rotating',
    'ball-spin-rotate',
    'ball-square-clockwise-spin',
    'ball-square-spin',
    'ball-triangle-path',
    'ball-zig-zag',
    'ball-zig-zag-deflect',
    'cog',
    'cube-transition',
    'fire',
    'line-scale',
    'line-scale-party',
    'line-scale-pulse-out',
    'line-scale-pulse-out-rapid',
    'line-spin-clockwise-fade',
    'line-spin-clockwise-fade-rotating',
    'line-spin-fade',
    'line-spin-fade-rotating',
    'pacman',
    'square-jelly-box',
    'square-loader',
    'square-spin',
    'timer',
    'triangle-skew-spin'
  ];
  ngOnChanges(): void {
    this.lang = this.cookieService.get('lang');
    this.translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
    });
    if (this.toggleSpinner) {
      this.showSpinner();
      return;
    }
    this.hideSpinner();
  }

  showSpinner() {
    this.spinnerIndex = parseInt(localStorage.getItem('spinnerIndex')) || 0;
    this.spinnerIndex >= this.spinnerTypesList.length ? this.spinnerIndex = 0 : this.spinnerIndex;
    localStorage.setItem('spinnerIndex', JSON.stringify(++this.spinnerIndex));
    this.spinner.show('spinner');
  }

  hideSpinner(): void {
    this.spinner.hide('spinner');
  }
}