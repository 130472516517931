import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
// import { CanActivate } from "@angular/router/src/utils/preactivation";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route
} from '@angular/router';
import { Observable } from 'rxjs';
import { AdminAuthService } from '../AdminAuth.Service';

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard implements CanActivate {
  constructor(private adminAuthService: AdminAuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isFakingLogin = state.root.queryParams.accessToken;

    if (this.adminAuthService.UserAuthState.IsLoggedIn) {
      if (isFakingLogin) {
        return true;
      }
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}