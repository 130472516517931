import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input() cardData: any;
  @Input() type: any;
  @Input() totalMaleFemale: any;

  lang: any;
  single: any = [];
  view: any[] = [160, 160];
  total: any = 0;


  colorScheme = {
    domain: ['#b2b2b2', '#0f435b', '#f1a943', '#7fbfe2', '#5cad8d', '#bfded1']
  };

  // pie
  showLabels = false;
  explodeSlices = false;
  doughnut = true;
  showLegend = false;
  gradient = false;

  constructor(public translate: TranslateService, private cookie: CookieService) {
    Object.assign(this, { single: this.single });
  }



  onResize(event) {
    if (this.type === 'clc') {
      this.sizingClc();
    } else {
      this.sizingGender();
    }
  }
  ngOnInit() {
    this.lang = this.cookie.get('lang');
    this.translate.onLangChange.subscribe((e: any) => {
      this.lang = e.lang;
      this.onLangChange();
    });
    this.onLangChange();
  }
  onLangChange() {
    if (this.type === 'clc') { // Customer Life Cycle
      this.sizingClc();

      this.colorScheme = {
        domain: ['#b2b2b2', '#0f435b', '#f1a943', '#7fbfe2', '#5cad8d', '#bfded1']
      };

      this.single = [
        {
          name: this.translate.instant('dashboard.lost'),
          value: this.cardData.lostCount
        },
        {
          name: this.translate.instant('dashboard.slippingAway'),
          value: this.cardData.riskCount
        },
        {
          name: this.translate.instant('dashboard.new'),
          value: this.cardData.newCount
        },
        {
          name: this.translate.instant('active'),
          value: this.cardData.bestCount
        },
        {
          name: this.translate.instant('dashboard.loyal'),
          value: this.cardData.loyalCount
        },
        {
          name: this.translate.instant('dashboard.promising'),
          value: this.cardData.promisingCount
        }
      ];
      this.total = this.cardData.lostCount + this.cardData.riskCount + this.cardData.newCount
        + this.cardData.bestCount + this.cardData.loyalCount + this.cardData.promisingCount;
    }

    if (this.type === 'ageAndGender') {
      this.sizingGender();
      this.total = this.totalMaleFemale;
      this.colorScheme = {
        domain: ['#efaa43', '#5cad8d', '#b2b2b2']
      };
      this.cardData.forEach(element => {
        this.single.push(
          {
            name: element.label,
            value: Math.round(element.value)
          }
        );
      });
    }
  }

  sizingClc() {
    if (innerWidth > 0 && innerWidth <= 800) {
      this.view = [190, 190];
    }

    if (innerWidth > 800 && innerWidth <= 1024) {
      this.view = [260, 260];
    }

    if (innerWidth > 1024 && innerWidth <= 1152) {
      this.view = [260, 260];
    }

    if (innerWidth > 1152 && innerWidth <= 1280) {
      this.view = [320, 320];
    }
    if (innerWidth > 1280 && innerWidth <= 1360) {
      this.view = [320, 320];
    }
    if (innerWidth > 1360 && innerWidth <= 1366) {
      this.view = [320, 320];
    }
    if (innerWidth > 1366 && innerWidth <= 1400) {
      this.view = [260, 260];
    }
    if (innerWidth > 1400 && innerWidth <= 1440) {
      this.view = [320, 320];
    }
    if (innerWidth > 1440 && innerWidth <= 1600) {
      this.view = [340, 340];
    }
    if (innerWidth > 1600 && innerWidth <= 1920) {
      this.view = [340, 340];
    }
    if (innerWidth > 1920) {
      this.view = [340, 340];
    }
  }


  sizingGender() {
    if (innerWidth > 0 && innerWidth <= 800) {
      this.view = [120, 120];
    }

    if (innerWidth > 800 && innerWidth <= 1024) {
      this.view = [140, 140];
    }

    if (innerWidth > 1024 && innerWidth <= 1152) {
      this.view = [160, 160];
    }

    if (innerWidth > 1152 && innerWidth <= 1280) {
      this.view = [160, 160];
    }
    if (innerWidth > 1280 && innerWidth <= 1360) {
      this.view = [170, 170];
    }
    if (innerWidth > 1360 && innerWidth <= 1366) {
      this.view = [170, 170];
    }
    if (innerWidth > 1366 && innerWidth <= 1400) {
      this.view = [150, 150];
    }
    if (innerWidth > 1400 && innerWidth <= 1440) {
      this.view = [160, 160];
    }
    if (innerWidth > 1440 && innerWidth <= 1600) {
      this.view = [160, 160];
    }
    if (innerWidth > 1600 && innerWidth <= 1920) {
      this.view = [160, 160];
    }
    if (innerWidth > 1920) {
      this.view = [160, 160];
    }
  }

}