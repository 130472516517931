export const single = [
  {
    name: 'new',
    value: 15
  },
  {
    name: 'lost',
    value: 3
  },
  {
    name: 'loyalty',
    value: 25
  },
  {
    name: 'slipping',
    value: 7
  },
  {
    name: 'active',
    value: 30
  }
];